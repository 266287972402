/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import { useQuery, useMutation } from '@apollo/client';
import { UPGRADE_STATUS, USER_QUERY } from '@app/apollo/queries';
import { useTranslation } from '@app/config/i18n';
import { redirectToRoute, setAuthCookie } from '@app/helpers';
import Link from '@atoms/Link';
import {
  IconButton,
  Toolbar,
} from '@mui/material';
import CustomDialog from '@app/components/molecules/CustomDialog';
import RoundedButton from '@app/components/atoms/RoundedButton';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Image from '@atoms/Image';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import _ from 'lodash';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { CREATE_SCIENTIST_PROFILE } from '@app/apollo/mutations';
import MutationsStatus from '@app/components/molecules/MutationsStatus';
import Typography from '@app/components/atoms/Typography';
import Spacer from '@atoms/Spacer';
import { MessageOutlined } from '@mui/icons-material';
import { useStateContext } from '@app/utils/state';
import { AppBar, LogoContainer, StyledMenu, UserAvatar, UserButton, MyDivider } from './style';
// import CookieAlert from '@molecules/common/CookieAlert';

const Logo = '/images/sv_logo_2.png';
const LogoAfterLogin = '/images/LogoAfterLogin.png';

const Header = ({ sidebar, onClick, hasElevation, linkedInUser }) => {
  const [scientistModal, setScientistModal] = useState(false);
  const [refetchScientist, setRefetchSCientist] = useState(false);

  const [auth, setAuth] = useState(false);
  // const [showCookieAlert, setShowCookieAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation(['common', 'applications', 'account', 'landing']);
  // const { user } = useUser();

  const { data, refetch } = useQuery(USER_QUERY);

  const user = data?.me;

  const scientistProfile = {
    name: user && user.name,
    profile_image: null,
    email: user && user.email,
    phone: '',
    field: '',
    degree: null,
    university: '',
    location: null,
    employer: '',
    website: '',
    social_links: [],
    expertise_areas: [],
    skills: [],
    research_interests: [],
    projects: [],
    publications: [],
  };

  const { data: { upgradeStatus } = {}, loading } = useQuery(UPGRADE_STATUS);

  const open = Boolean(anchorEl);

  const router = useRouter();

  if (!auth && user) {
    setAuth(true);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToProfile = () => {
    redirectToRoute({}, 'update_profile');
  };

  const redirectToUpgradeAcount = () => {
    redirectToRoute({}, 'account_upgrade');
  };

  const redirectToUpdateScientistProfile = () => {
    redirectToRoute({}, 'scientist_profile_update', {
      params: { slug: user.scientist_profile.slug },
    });
  };

  const handleScientistDialogOpen = () => {
    setScientistModal(true);
  };

  const handleScientistDialogClose = () => {
    setScientistModal(false);
  };

  const handleCreateScientistProfile = () => {
    handleScientistDialogOpen();
  };

  const signOutHandler = () => {
    setAuthCookie(null);
    router.push('/');
    // Router.reload();
  };

  const imageURL = _.get(
    _.last(_.get(linkedInUser, 'profilePicture.displayImage~.elements', '')),
    'identifiers[0].identifier',
    ''
  );

  const getHomeHref = () => {
    let route = '/';
    if (auth && user) {
      if (user.selected_type && user.selected_type === 'organiser') {
        route = 'my_opportunities';
      } else {
        route = 'opportunity_search';
      }
    }
    return route;
  };

  if (refetchScientist && user.scientist_profile) {
    redirectToRoute({}, 'scientist_profile_update', {
      params: { slug: user.scientist_profile.slug },
    });
  }

  const handleSuccessCreateScientistProfile = () => {
    handleScientistDialogClose();
    refetch();
    setRefetchSCientist(true);
  };

  const [
    createScientistProfileMutation,
    {
      loading: scientistProfileMutationLoading,
      error: scientistProfileMutationError,
      data: scientistProfileMutationData,
    },
  ] = useMutation(CREATE_SCIENTIST_PROFILE, {
    onCompleted: handleSuccessCreateScientistProfile,
  });

  const createScientistProfileHandler = () => {
    const { ...input } = scientistProfile;
    createScientistProfileMutation({
      variables: {
        input,
      },
    });
  };

  const { userInbox, userInboxRef } = useStateContext();

  return (
    <>
      <MutationsStatus
        loading={scientistProfileMutationLoading}
        error={scientistProfileMutationError}
        success={scientistProfileMutationData}
      />
      {/* <NoSsr>{showCookieAlert && <CookieAlert closePopup={closePopupHandler} />}</NoSsr> */}
      <AppBar
        sx={{
          backgroundColor:
            router.pathname === '/index' ? '#236B81 !important' : '#0f162a !important',
        }}
        haselevation={hasElevation ? 1 : 0}
      >
        <Toolbar>
          {sidebar && (
            <IconButton
              sx={{ mr: 1 }}
              color="inherit"
              aria-label="open drawer"
              onClick={onClick}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Spacer width="15px" height="10px" />
          <LogoContainer>
            {router.pathname === '/index' ? (
              <Link href={getHomeHref()} color="inherit" title="home page">
                <Image
                  bottom="0.3125rem"
                  //  width="160px"
                  height="39.8px"
                  src={Logo}
                  alt="SciVenia"
                />
                {/* <Image bottom="0.3125rem" width="143px" src={Logo}  /> */}
                {/* <Typography variant="h6">SciVenia</Typography> */}
              </Link>
            ) : (
              <Link href={getHomeHref()} color="inherit" title="home page">
                <Image
                  bottom="0.3125rem"
                  // width="160px"
                  height="39.8px"
                  src={Logo}
                  alt="SciVenia"
                />
                {/* <Image bottom="0.3125rem" width="143px" src={Logo}  /> */}
                {/* <Typography variant="h6">SciVenia</Typography> */}
              </Link>
            )}
            {/* <SearchIconBox>
              {' '}
              <SearchIcon fontSize="small"/>
            </SearchIconBox> */}
          </LogoContainer>
        </Toolbar>
      </AppBar>

      <CustomDialog
        open={scientistModal}
        onClose={handleScientistDialogClose}
        title={t('howItWorks:create_profile')}
        cancelButtonText={t('common:cancel')}
        acceptButtonText={t('common:yes')}
        loading={scientistProfileMutationLoading}
        handleConfirm={createScientistProfileHandler}
      >
        {t('landing:want_create')}
      </CustomDialog>
    </>
  );
};

Header.propTypes = {
  sidebar: PropTypes.bool,
  onClick: PropTypes.func,
  hasElevation: PropTypes.bool,
  linkedInUser: PropTypes.object,
};

Header.defaultProps = {
  sidebar: false,
  hasElevation: true,
  onClick: () => {},
  linkedInUser: {},
};

export default Header;
