import styled from 'styled-components';
import { Grid } from '@mui/material';
import { deviceSizes } from '../../../config/devices';

import Paper from '../../atoms/Paper';

export const BoxContianer = styled.div`
  // padding-top: 190px;
  // padding-bottom: 240px;
  height: 1120px;
  background-color: #0f162a;
  position: relative;

  @media only screen and (min-width: ${deviceSizes.mobileS}) and (max-width: ${deviceSizes.mobileM}) {
    > .MuiContainer-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const FormContainer = styled(Grid)`
  && {
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 40px;

    @media only screen and (max-width: ${deviceSizes.mobileL}) {
      padding-left: 16px;
      padding-right: 10px;
    }
  }
`;

export const ImageContainer = styled(Grid)`
  && {
    margin-top: -2rem;
    margin-bottom: -2rem;
    background-size: cover;
    background-position: right;
    overflow: hidden;
    background-image: url(${({ src }) => src});
    display: flex;
    justify-content: center;
    align-items: center;
    // border-top-right-radius: 0px;
    // border-bottom-right-radius: 0px;
  }
`;

export const StyledPaper = styled(Paper)`
  && {
    padding-right: 0;

    border-radius: 54px;
    @media (max-width: ${deviceSizes.mobileL}) {
      padding-left: 0;
    }
  }
`;
