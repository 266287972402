import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from '@mui/material';
import Head from 'next/head';
import Hidden from '@mui/material/Hidden';
import Image from 'next/image';
import { BoxContianer, ImageContainer, StyledPaper, FormContainer } from './style';
import AuthHeader from '../../organisms/common/AuthHeader';
import Footer from '../../organisms/common/Footer';
// import Paper from '../../atoms/Paper';
// import landingImage from '../../../static/images/landing.jpg';



const AuthTwoColumns = ({ children, title, img }) => (
  <>
    <Head>
      <title>{`Scivenia ${title}`}</title>
    </Head>
    <AuthHeader />
    <BoxContianer src={img} >
      <div className="bg-position-login" />
      <Container maxWidth="md" sx={{ position: 'absolute', top: '200px', left: '0', right: '0', margin: '0 auto' }}>
        <StyledPaper>
          <Grid container >
            <FormContainer item xs={12} sm={12} md={8}>
              {children}
            </FormContainer>
            <Hidden only={['sm', 'xs']}>
              <ImageContainer item src={img} xs={12} sm={4}>
                <Image className="logo" width="100%" height="100%" src="/images/login_logo.png" />
              </ImageContainer>
            </Hidden>

          </Grid>
        </StyledPaper>
      </Container>
    </BoxContianer>
    <Footer />
  </>
);


AuthTwoColumns.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  img: PropTypes.string,
};

AuthTwoColumns.defaultProps = {
  title: '',
  img: "/images/landing.jpg"
};

export default AuthTwoColumns;



